// All icons sourced from https://devicon.dev/

export const technologyLogos = {
	"App Store":
		"https://cdn.jsdelivr.net/gh/devicons/devicon/icons/apple/apple-original.svg",
	"Play Store":
		"https://cdn.jsdelivr.net/gh/devicons/devicon/icons/android/android-plain.svg",
	GitHub:
		"https://cdn.jsdelivr.net/gh/devicons/devicon/icons/github/github-original.svg",
	Website:
		"https://cdn.jsdelivr.net/gh/devicons/devicon/icons/chrome/chrome-original.svg",
	Deployment:
		"https://cdn.jsdelivr.net/gh/devicons/devicon/icons/googlecloud/googlecloud-plain.svg",
	Flask:
		"https://cdn.jsdelivr.net/gh/devicons/devicon/icons/flask/flask-original.svg",
	React:
		"https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg",
	"React Native":
		"https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg",
	Python:
		"https://cdn.jsdelivr.net/gh/devicons/devicon/icons/python/python-original.svg",
	Pytorch:
		"https://cdn.jsdelivr.net/gh/devicons/devicon/icons/pytorch/pytorch-original.svg",
	Flutter:
		"https://cdn.jsdelivr.net/gh/devicons/devicon/icons/flutter/flutter-original.svg",
	Firebase:
		"https://cdn.jsdelivr.net/gh/devicons/devicon/icons/firebase/firebase-plain.svg",
	Swift:
		"https://cdn.jsdelivr.net/gh/devicons/devicon/icons/swift/swift-original.svg",
	TypeScript:
		"https://cdn.jsdelivr.net/gh/devicons/devicon/icons/typescript/typescript-original.svg",
	JavaScript:
		"https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-original.svg",
	NodeJS:
		"https://cdn.jsdelivr.net/gh/devicons/devicon/icons/nodejs/nodejs-original.svg",
	Express:
		"https://cdn.jsdelivr.net/gh/devicons/devicon/icons/express/express-original.svg",
	Figma:
		"https://cdn.jsdelivr.net/gh/devicons/devicon/icons/figma/figma-original.svg",
	NextJS:
		"https://cdn.jsdelivr.net/gh/devicons/devicon/icons/nextjs/nextjs-original.svg",
	PostgreSQL:
		"https://cdn.jsdelivr.net/gh/devicons/devicon/icons/postgresql/postgresql-original.svg",
	TailwindCSS:
		"https://cdn.jsdelivr.net/gh/devicons/devicon/icons/tailwindcss/tailwindcss-plain.svg",
	DigitalOcean:
		"https://cdn.jsdelivr.net/gh/devicons/devicon/icons/digitalocean/digitalocean-original.svg",
	ElasticSearch:
		"https://user-images.githubusercontent.com/9143253/47912437-f749bc00-de98-11e8-9669-e97f58b8be2e.png",
	Logstash:
		"https://static-www.elastic.co/v3/assets/bltefdd0b53724fa2ce/blt8b679e63f2b49b27/5d082d93877575d0584761c0/logo-logstash-32-color.svg",
	Kibana:
		"https://static-www.elastic.co/v3/assets/bltefdd0b53724fa2ce/blt4466841eed0bf232/5d082a5e97f2babb5af907ee/logo-kibana-32-color.svg",
	tRPC: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/trpc/trpc-original.svg",
	prisma:
		"https://cdn.jsdelivr.net/gh/devicons/devicon/icons/prisma/prisma-original.svg",
};

// While the text for the technology logos is always just the name of the tecnology, for linking to the github repo,
// the company website, the the app store, the play store, or our deployed website, we need custom descriptions.
export const linkDescriptions = {
	AppStore: "Download on the App Store",
};
