export const footerLinks = [
  "About",
  "Join",
  "Projects",
  // "Education",
  "Work With Us",
];

// This is dumb, it should be fixed, but I'm lazy and tired
export const lowercaseFooterLinks = [
  "about",
  "join",
  "projects",
  // "education",
  "workwithus",
];
